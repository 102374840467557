import { useStorage } from "@vueuse/core";

import type { AuthTokens } from "@/utils/types/auth";

const authStorage = useStorage<AuthTokens>(`auth`, {
  token: undefined,
  refreshToken: undefined,
});

const isLoggedIn = computed(() => !!authStorage.value.token);
const isLoggedInWithRememberMe = computed(
  () => !!authStorage.value.token && !!authStorage.value.refreshToken
);

export const useAuthStorage = () => {
  const setTokens = ({ token, refreshToken }: AuthTokens) => {
    authStorage.value.token = token;
    if (refreshToken) {
      authStorage.value.refreshToken = refreshToken;
    }
  };

  const resetTokens = () => {
    authStorage.value.token = undefined;
    authStorage.value.refreshToken = undefined;
  };

  return {
    authStorage,
    isLoggedIn,
    isLoggedInWithRememberMe,
    setTokens,
    resetTokens,
  };
};
