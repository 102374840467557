import revive_payload_client_FroUdQdgp1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OSgrMeDG7g from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ma3U64yJs1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_D03a1RYMgE from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_AWgXAViDMz from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_jk2Trhsgq4 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_24Zw0oDY2F from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/admin/.nuxt/unocss.mjs";
import chunk_reload_client_RB0Q21XWQm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.2_floating-vue@5_p4cyrahdrscoz2owbqvliiz4ua/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_VfGcjrvSkj from "/vercel/path0/apps/admin/plugins/i18n.ts";
import urql_FlK1jaV38U from "/vercel/path0/apps/admin/plugins/urql.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/apps/admin/plugins/vuetify.ts";
export default [
  revive_payload_client_FroUdQdgp1,
  unhead_OSgrMeDG7g,
  router_Ma3U64yJs1,
  payload_client_D03a1RYMgE,
  check_outdated_build_client_AWgXAViDMz,
  plugin_vue3_jk2Trhsgq4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_24Zw0oDY2F,
  unocss_MzCDxu9LMj,
  chunk_reload_client_RB0Q21XWQm,
  i18n_VfGcjrvSkj,
  urql_FlK1jaV38U,
  vuetify_7h9QAQEssH
]