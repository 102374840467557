import { default as indexkYyVcp4xldMeta } from "/vercel/path0/apps/admin/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93k8NK7Py9sFMeta } from "/vercel/path0/apps/admin/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as verify_45_91otpId_93n7lBhV2lBaMeta } from "/vercel/path0/apps/admin/pages/auth/verify-[otpId].vue?macro=true";
import { default as indexGLD2GXsKzXMeta } from "/vercel/path0/apps/admin/pages/dashboard/admins/index.vue?macro=true";
import { default as indexf9Pm9Bm6xkMeta } from "/vercel/path0/apps/admin/pages/dashboard/affiliates/index.vue?macro=true";
import { default as indexdFqijecgCtMeta } from "/vercel/path0/apps/admin/pages/dashboard/financial/index.vue?macro=true";
import { default as index8B1yqUqPilMeta } from "/vercel/path0/apps/admin/pages/dashboard/index.vue?macro=true";
import { default as _91orderId_939E65HCjyaWMeta } from "/vercel/path0/apps/admin/pages/dashboard/orders/[orderId].vue?macro=true";
import { default as indexhWEYgp7GcUMeta } from "/vercel/path0/apps/admin/pages/dashboard/orders/index.vue?macro=true";
import { default as add_45subscriptionNPOoTqv2LLMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/add-subscription.vue?macro=true";
import { default as indexg1AHg6gJImMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as indexrFFK7hG797Meta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/index.vue?macro=true";
import { default as syncaccessPexIAYVOGaMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/syncaccess.vue?macro=true";
import { default as indexrp49EyA1U1Meta } from "/vercel/path0/apps/admin/pages/dashboard/plans/index.vue?macro=true";
import { default as _91id_93671RhuD0xaMeta } from "/vercel/path0/apps/admin/pages/dashboard/print/order/[id].vue?macro=true";
import { default as indexR6vqlqRhheMeta } from "/vercel/path0/apps/admin/pages/dashboard/promotions/index.vue?macro=true";
import { default as _91userId_93Lihjb5weFIMeta } from "/vercel/path0/apps/admin/pages/dashboard/users/[userId].vue?macro=true";
import { default as indexoqM6PdO93CMeta } from "/vercel/path0/apps/admin/pages/dashboard/users/index.vue?macro=true";
import { default as dashboardbE7pCL9f6LMeta } from "/vercel/path0/apps/admin/pages/dashboard.vue?macro=true";
import { default as index1xexOXsc4pMeta } from "/vercel/path0/apps/admin/pages/index.vue?macro=true";
export default [
  {
    name: indexkYyVcp4xldMeta?.name ?? "auth-password-reset",
    path: indexkYyVcp4xldMeta?.path ?? "/auth/password/reset",
    meta: indexkYyVcp4xldMeta || {},
    alias: indexkYyVcp4xldMeta?.alias || [],
    redirect: indexkYyVcp4xldMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93k8NK7Py9sFMeta?.name ?? "auth-password-reset-submit-uuid",
    path: _91uuid_93k8NK7Py9sFMeta?.path ?? "/auth/password/reset/submit/:uuid()",
    meta: _91uuid_93k8NK7Py9sFMeta || {},
    alias: _91uuid_93k8NK7Py9sFMeta?.alias || [],
    redirect: _91uuid_93k8NK7Py9sFMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: verify_45_91otpId_93n7lBhV2lBaMeta?.name ?? "auth-verify-otpId",
    path: verify_45_91otpId_93n7lBhV2lBaMeta?.path ?? "/auth/verify-:otpId()",
    meta: verify_45_91otpId_93n7lBhV2lBaMeta || {},
    alias: verify_45_91otpId_93n7lBhV2lBaMeta?.alias || [],
    redirect: verify_45_91otpId_93n7lBhV2lBaMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/auth/verify-[otpId].vue").then(m => m.default || m)
  },
  {
    name: dashboardbE7pCL9f6LMeta?.name ?? undefined,
    path: dashboardbE7pCL9f6LMeta?.path ?? "/dashboard",
    meta: dashboardbE7pCL9f6LMeta || {},
    alias: dashboardbE7pCL9f6LMeta?.alias || [],
    redirect: dashboardbE7pCL9f6LMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: indexGLD2GXsKzXMeta?.name ?? "dashboard-admins",
    path: indexGLD2GXsKzXMeta?.path ?? "admins",
    meta: indexGLD2GXsKzXMeta || {},
    alias: indexGLD2GXsKzXMeta?.alias || [],
    redirect: indexGLD2GXsKzXMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/admins/index.vue").then(m => m.default || m)
  },
  {
    name: indexf9Pm9Bm6xkMeta?.name ?? "dashboard-affiliates",
    path: indexf9Pm9Bm6xkMeta?.path ?? "affiliates",
    meta: indexf9Pm9Bm6xkMeta || {},
    alias: indexf9Pm9Bm6xkMeta?.alias || [],
    redirect: indexf9Pm9Bm6xkMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: indexdFqijecgCtMeta?.name ?? "dashboard-financial",
    path: indexdFqijecgCtMeta?.path ?? "financial",
    meta: indexdFqijecgCtMeta || {},
    alias: indexdFqijecgCtMeta?.alias || [],
    redirect: indexdFqijecgCtMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/financial/index.vue").then(m => m.default || m)
  },
  {
    name: index8B1yqUqPilMeta?.name ?? "dashboard",
    path: index8B1yqUqPilMeta?.path ?? "",
    meta: index8B1yqUqPilMeta || {},
    alias: index8B1yqUqPilMeta?.alias || [],
    redirect: index8B1yqUqPilMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_939E65HCjyaWMeta?.name ?? "dashboard-orders-orderId",
    path: _91orderId_939E65HCjyaWMeta?.path ?? "orders/:orderId()",
    meta: _91orderId_939E65HCjyaWMeta || {},
    alias: _91orderId_939E65HCjyaWMeta?.alias || [],
    redirect: _91orderId_939E65HCjyaWMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexhWEYgp7GcUMeta?.name ?? "dashboard-orders",
    path: indexhWEYgp7GcUMeta?.path ?? "orders",
    meta: indexhWEYgp7GcUMeta || {},
    alias: indexhWEYgp7GcUMeta?.alias || [],
    redirect: indexhWEYgp7GcUMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: add_45subscriptionNPOoTqv2LLMeta?.name ?? "dashboard-organizations-orgId-add-subscription",
    path: add_45subscriptionNPOoTqv2LLMeta?.path ?? "organizations/:orgId()/add-subscription",
    meta: add_45subscriptionNPOoTqv2LLMeta || {},
    alias: add_45subscriptionNPOoTqv2LLMeta?.alias || [],
    redirect: add_45subscriptionNPOoTqv2LLMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/add-subscription.vue").then(m => m.default || m)
  },
  {
    name: indexg1AHg6gJImMeta?.name ?? "dashboard-organizations-orgId",
    path: indexg1AHg6gJImMeta?.path ?? "organizations/:orgId()",
    meta: indexg1AHg6gJImMeta || {},
    alias: indexg1AHg6gJImMeta?.alias || [],
    redirect: indexg1AHg6gJImMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrFFK7hG797Meta?.name ?? "dashboard-organizations",
    path: indexrFFK7hG797Meta?.path ?? "organizations",
    meta: indexrFFK7hG797Meta || {},
    alias: indexrFFK7hG797Meta?.alias || [],
    redirect: indexrFFK7hG797Meta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: syncaccessPexIAYVOGaMeta?.name ?? "dashboard-organizations-syncaccess",
    path: syncaccessPexIAYVOGaMeta?.path ?? "organizations/syncaccess",
    meta: syncaccessPexIAYVOGaMeta || {},
    alias: syncaccessPexIAYVOGaMeta?.alias || [],
    redirect: syncaccessPexIAYVOGaMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/syncaccess.vue").then(m => m.default || m)
  },
  {
    name: indexrp49EyA1U1Meta?.name ?? "dashboard-plans",
    path: indexrp49EyA1U1Meta?.path ?? "plans",
    meta: indexrp49EyA1U1Meta || {},
    alias: indexrp49EyA1U1Meta?.alias || [],
    redirect: indexrp49EyA1U1Meta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/plans/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93671RhuD0xaMeta?.name ?? "dashboard-print-order-id",
    path: _91id_93671RhuD0xaMeta?.path ?? "print/order/:id()",
    meta: _91id_93671RhuD0xaMeta || {},
    alias: _91id_93671RhuD0xaMeta?.alias || [],
    redirect: _91id_93671RhuD0xaMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/print/order/[id].vue").then(m => m.default || m)
  },
  {
    name: indexR6vqlqRhheMeta?.name ?? "dashboard-promotions",
    path: indexR6vqlqRhheMeta?.path ?? "promotions",
    meta: indexR6vqlqRhheMeta || {},
    alias: indexR6vqlqRhheMeta?.alias || [],
    redirect: indexR6vqlqRhheMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93Lihjb5weFIMeta?.name ?? "dashboard-users-userId",
    path: _91userId_93Lihjb5weFIMeta?.path ?? "users/:userId()",
    meta: _91userId_93Lihjb5weFIMeta || {},
    alias: _91userId_93Lihjb5weFIMeta?.alias || [],
    redirect: _91userId_93Lihjb5weFIMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexoqM6PdO93CMeta?.name ?? "dashboard-users",
    path: indexoqM6PdO93CMeta?.path ?? "users",
    meta: indexoqM6PdO93CMeta || {},
    alias: indexoqM6PdO93CMeta?.alias || [],
    redirect: indexoqM6PdO93CMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index1xexOXsc4pMeta?.name ?? "index",
    path: index1xexOXsc4pMeta?.path ?? "/",
    meta: index1xexOXsc4pMeta || {},
    alias: index1xexOXsc4pMeta?.alias || [],
    redirect: index1xexOXsc4pMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/pages/index.vue").then(m => m.default || m)
  }
]