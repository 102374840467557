import { useClientHandle } from "@urql/vue";
import { initGraphQLTada } from "gql.tada";
import type { JsonSchema, OrderByType } from "@mono/validation/lib";
import type { introspection } from "@/graphql-env.d.ts";

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    uuid: string;
    _uuid: string[];
    date: string;
    bigint: number;
    numeric: number;
    timestamp: string;
    timestamptz: string;
    order_by: OrderByType;
    json: JsonSchema;
    jsonb: JsonSchema;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from "gql.tada";
export { readFragment } from "gql.tada";

// this is to be used for direct executeQuery and executeMutation calls
export const useUrql = () => {
  const { $urql } = useNuxtApp();
  return {
    $urql,
    graphql,
  };
};

// this is to be used in components for useQuery and useMutation options
export const useUrqlClient = () => {
  const urql = useClientHandle();
  return {
    urql,
    graphql,
  };
};
