import urql, {
  createClient,
  cacheExchange,
  fetchExchange,
  mapExchange,
} from "@urql/vue";
import { authExchange } from "@urql/exchange-auth";
import { jwtDecode } from "jwt-decode";
import { useAuthStore } from "@/stores/auth";
import { graphql } from "@/composables/useUrql";

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig();

  const debugExchange = mapExchange({
    onError(error, operation) {
      if (config.public.DEBUG) {
        console.error(
          "urql:error",
          `The operation ${operation.key} has errored with:`,
          error
        );
      }
    },
    onResult(result) {
      if (config.public.DEBUG) {
        console.debug("urql:operation", result);
      }
    },
  });

  const authenticationExchange = authExchange(
    // auth exchange most return a promise
    // because its an async exchange
    // eslint-disable-next-line require-await
    async ({ appendHeaders, mutate }) => {
      const { authStorage, isLoggedIn, setTokens } = useAuthStorage();
      const { logout } = useAuthStore();
      const router = useRouter();

      return {
        addAuthToOperation(operation) {
          if (isLoggedIn.value) {
            return appendHeaders(operation, {
              Authorization: `Bearer ${authStorage.value.token}`,
            });
          }

          return operation;
        },
        willAuthError() {
          const token = authStorage.value.token;
          if (!token) {
            return false;
          }
          // return true if jwt expires within 60 seconds
          const { exp } = jwtDecode<{ exp: number }>(authStorage.value.token!);
          const timeToExpire = Math.round(exp - Date.now() / 1000);
          if (config.public.DEBUG) {
            console.debug("🚀 ~ willAuthError ~ timeToExpire:", timeToExpire);
          }
          return timeToExpire < 60;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === "UNAUTHORIZED"
          );
        },
        async refreshAuth() {
          const refreshMutation = graphql(/* GraphQL */ `
            mutation RefreshAuth($refreshToken: String!) {
              userRefreshAuthAction(refreshToken: $refreshToken) {
                token
              }
            }
          `);
          try {
            // will end session for users who logged in
            // with remember me unchecked
            if (!authStorage.value.refreshToken) {
              throw new Error("No refresh token");
            }

            // attempt to refresh the token
            const result = await mutate(refreshMutation, {
              refreshToken: authStorage.value.refreshToken!,
            });

            if (!result.data?.userRefreshAuthAction) {
              throw new Error("Invalid refresh token");
            }

            setTokens({
              token: result.data.userRefreshAuthAction.token,
              refreshToken: authStorage.value.refreshToken,
            });
          } catch (error) {
            logout();
            router.push({ name: "index" });
          }
        },
      };
    }
  );

  const urqlClient = createClient({
    url:
      config.public.API_TARGET !== "staging"
        ? config.public.GRAPHQL_URL
        : config.public.GRAPHQL_URL.replace("http:", "https:").replace(
            "80",
            "3030"
          ),
    requestPolicy: "network-only",
    exchanges: [
      // devtoolsExchange,
      cacheExchange,
      debugExchange,
      authenticationExchange,
      fetchExchange,
    ],
    fetchOptions: {
      headers: {},
    },
  });

  vueApp.use(urql, urqlClient);

  return {
    provide: {
      urql: urqlClient,
    },
  };
});
