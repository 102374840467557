export const adminRoles = ["superadmin", "manager", "support"] as const;

export type AdminRole = (typeof adminRoles)[number];

export const adminCheckPermissions = ({
  adminRoles,
  role,
}: {
  adminRoles: AdminRole[];
  role?: AdminRole;
}) => {
  // access is denied by default if the user is not a superadmin/manager
  if (role === "superadmin" && adminRoles.includes("superadmin") === false) {
    return false;
  }

  if (role === "support") {
    return true;
  }

  return adminRoles.includes("superadmin") || adminRoles.includes("manager");
};
