import { useAuthStore } from "@/stores/auth";

export default defineNuxtRouteMiddleware((route) => {
  // !solves an edge case where route.name isn't found
  if (!route?.name) return;

  const isInDashboard = route.name!.toString().startsWith("dashboard");
  const { isLoggedIn } = useAuthStorage();
  const { checkPermission } = useAuthorization();

  if (isLoggedIn.value === true && isInDashboard === false) {
    return navigateTo({ name: "dashboard" });
  }
  if (isLoggedIn.value === false && isInDashboard === true) {
    return navigateTo({ name: "index" });
  }

  if (isLoggedIn.value === true && isInDashboard === true) {
    const { adminRoles, init } = useAuthStore();
    const { accessRole } = route.meta.ruleOrRole || {};

    if (adminRoles === undefined) {
      init();
    }

    // always check if the user has the permission to access the route
    if (checkPermission({ role: accessRole }) === false) {
      return navigateTo({ name: "dashboard" });
    }
  }
});
