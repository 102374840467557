import { jwtDecode } from "jwt-decode";
import type { LoginVerificationArgsSchema } from "@mono/validation/lib/Auth";
import type { AuthJwtPayload } from "@/utils/types/auth";
import { graphql } from "@/composables/useUrql";

const userDetailsQuery = graphql(/* GraphQL */ `
  query UserDetails($id: uuid!) {
    details: users_by_pk(id: $id) {
      email
      fullName
      locale
      phone
      phoneCC
      countryId
      country {
        displayName
        displayNameTranslations
      }
    }
  }
`);

const requestPasswordResetQuery = graphql(/* GraphQL */ `
  mutation RequestPasswordReset($email: String!) {
    passwordResetRequestAction(email: $email) {
      success
    }
  }
`);

const passwordResetQuery = graphql(/* GraphQL */ `
  mutation PasswordReset($linkUUID: uuid!, $newPassword: String!) {
    passwordResetSubmitAction(linkUUID: $linkUUID, newPassword: $newPassword) {
      success
    }
  }
`);

const loginVerificationMutation = graphql(/* GraphQL */ `
  mutation LoginVerification(
    $otpCode: String!
    $otpId: uuid!
    $email: String!
    $rememberMe: Boolean
  ) {
    loginVerificationAction(
      otpCode: $otpCode
      otpId: $otpId
      email: $email
      rememberMe: $rememberMe
    ) {
      refreshToken
      token
    }
  }
`);

type userDetails = ResultOf<typeof userDetailsQuery>["details"];
type RequestPasswordResetMutationVariables = VariablesOf<
  typeof requestPasswordResetQuery
>;
type PasswordResetMutationVariables = VariablesOf<typeof passwordResetQuery>;

export const useAuthStore = defineStore("auth", () => {
  // states
  const userId = ref<string | undefined>(undefined);
  const adminRoles = ref<AuthJwtPayload["adminRoles"] | undefined>(undefined);
  const userDetails = ref<userDetails | undefined>(undefined);
  const { resetTokens, setTokens, authStorage, isLoggedIn } = useAuthStorage();
  const autoLogoutInterval = ref<NodeJS.Timeout | undefined>(undefined);

  // composables
  const router = useRouter();

  // userDetails setter
  const setUserDetails = (user: userDetails) => {
    userDetails.value = structuredClone(user);
  };

  // reset handler
  const resetUser = () => {
    // reset auth local storage
    resetTokens();
    // reset userId
    userId.value = undefined;
    // reset userDetails
    userDetails.value = undefined;
  };

  // fetch user details handler
  const fetchUserDetails = async (userId: string) => {
    const { $urql } = useUrql();

    try {
      const { data, error } = await $urql
        .query(userDetailsQuery, { id: userId })
        .toPromise();

      if (error) throw error;

      return data?.details;
    } catch (error) {}
  };

  // watch userId
  watch(userId, async (id) => {
    if (id) {
      const user = await fetchUserDetails(id);
      if (user) {
        setUserDetails(user);
      }
    }
  });

  const login = async (args: LoginVerificationArgsSchema) => {
    const { $urql } = useUrql();

    try {
      const { data, error } = await $urql
        .mutation(loginVerificationMutation, args)
        .toPromise();

      if (error) throw error;

      if (data?.loginVerificationAction) {
        const { token, refreshToken } = data.loginVerificationAction;
        const authDetails = jwtDecode<AuthJwtPayload>(token);

        setTokens({ token, refreshToken });

        userId.value = authDetails.userId;
        adminRoles.value = authDetails.adminRoles;
        // set auto logout interval
        // 4 hours
        if (autoLogoutInterval.value) {
          // clear previous interval - debounce
          clearInterval(autoLogoutInterval.value);
        }
        autoLogoutInterval.value = setInterval(() => {
          logout();
        }, 1000 * 60 * 60 * 4);

        return { success: true };
      }

      return { success: true };
    } catch (error) {
      return { success: false };
    }
  };

  // Logout mutation
  const logout = () => {
    router.push({ name: "index" });
    resetUser();
  };

  // request password reset mutation
  const requestPasswordReset = async ({
    email,
  }: RequestPasswordResetMutationVariables) => {
    const { $urql } = useUrql();

    try {
      const { data, error } = await $urql
        .mutation(requestPasswordResetQuery, { email })
        .toPromise();

      if (error) throw error;

      return {
        hasError: !data?.passwordResetRequestAction.success,
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  // password reset  mutation
  const passwordReset = async ({
    linkUUID,
    newPassword,
  }: PasswordResetMutationVariables) => {
    const { $urql } = useUrql();

    try {
      const { data, error } = await $urql
        .mutation(passwordResetQuery, { linkUUID, newPassword })
        .toPromise();

      if (error) throw error;

      return {
        hasVerified: data?.passwordResetSubmitAction.success || false,
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  const init = () => {
    if (isLoggedIn.value && !userId.value) {
      const decodedUserDetails = jwtDecode<AuthJwtPayload>(
        authStorage.value.token!
      );

      userId.value = decodedUserDetails.userId;
      adminRoles.value = decodedUserDetails.adminRoles;
    }
  };

  return {
    // getters
    userId,
    userDetails,
    adminRoles,

    // setters
    login,
    logout,
    requestPasswordReset,
    passwordReset,
    init,
  };
});
