import {
  adminCheckPermissions,
  type AdminRole,
} from "@mono/constants/lib/adminAccessRules";
import { useAuthStore } from "@/stores/auth";

export const useAuthorization = () => {
  const authStore = useAuthStore();

  const checkPermission = ({ role }: { role?: AdminRole } = {}) => {
    const adminRoles = authStore.adminRoles;

    if (adminRoles === undefined) {
      return false;
    }

    return adminCheckPermissions({ role, adminRoles });
  };

  return {
    checkPermission,
  };
};

export const usePermissionsGuard = ({ role }: { role?: AdminRole } = {}) => {
  const authStore = useAuthStore();

  const isAuthorized = computed(() => {
    const adminRoles = authStore.adminRoles;

    if (adminRoles === undefined) {
      return false;
    }

    return adminCheckPermissions({ role, adminRoles });
  });

  return { isAuthorized };
};
